<template>
  <div class="sendWashRecord">
    <div class="contentView">
      <el-table
        style="width: 100%;"
        ref="selectedStaffTable"
        v-loading="sendWashRecordLoading"
        :data="tableData"
        border
      >
        <el-table-column
          prop="serialNum"
          align="center"
          label="流水号"
        ></el-table-column>
        <el-table-column
          prop="storeUserName"
          align="center"
          label="送洗人"
        ></el-table-column>
        <el-table-column
          prop="operateNum"
          align="center"
          width="100"
          label="件数"
        ></el-table-column>
        <el-table-column
          prop="sendWashFactory"
          align="center"
          label="送洗工厂"
        ></el-table-column>
        <el-table-column
          prop="sendWashFactoryUserName"
          align="center"
          label="接收人"
        ></el-table-column>
        <el-table-column
          prop="operateTime"
          align="center"
          width="280"
          label="操作时间"
        ></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="sendWashRecordDetail(scope.row)"
            >
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px; float: right;">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      style="height: auto;"
      :visible.sync="dialogTableVisible"
      width="70%"
      :modal-append-to-body="false"
      append-to-body
    >
      <el-table
        header-row-class-name="thClassNameDialog"
        row-class-name="trClassNameDialog"
        :data="gridData"
        v-loading="sendWashRecordDetailLoading"
      >
        <el-table-column
          property="serialNum"
          align="center"
          width="180"
          label="送洗单号"
        ></el-table-column>
        <el-table-column
          prop="clothesNumber"
          align="center"
          label="内部衣物号"
        ></el-table-column>
        <el-table-column
          prop="outClothesNumber"
          align="center"
          label="外部衣物号"
        ></el-table-column>
        <el-table-column
          property="clothesName"
          align="center"
          label="衣物名称"
        ></el-table-column>
        <el-table-column
          property="clothesServiceName"
          align="center"
          label="服务名称"
        ></el-table-column>
        <el-table-column
          property="blemisheDesc"
          align="center"
          label="衣物描述"
        ></el-table-column>

        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="withdrawClothesSendBefore(scope.row)"
            >
              撤回上一步
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <template slot="footer">
        <el-button @click="printPaper()">打印送洗单</el-button>
      </template>
    </el-dialog>
    <el-dialog
      title="送洗撤销"
      :visible="repealWashVisible"
      width="600px"
      center
      :modal-append-to-body="false"
      append-to-body
      :show-close="false"
    >
      <div style="display: table;margin: 0 auto; font-size: 15px">
        <div style="margin: 20px 0; display: flex;">
          <span style="width:110px;text-align:right">撤销衣物号：</span>
          <span>{{ rowData.outClothesNumber }}</span>
        </div>
        <div style="margin: 20px 0; display: flex;">
          <span style="width:110px;text-align:right">撤销衣物名称：</span>
          <span>{{ rowData.clothesName }}</span>
        </div>
        <div style="margin: 20px 0;display: flex;">
          <span style="width:110px;text-align:right">撤销原因：</span>
          <el-input
            type="textarea"
            placeholder="请输入撤销原因"
            v-model.trim="reason"
            maxlength="50"
            style="width: 200px"
            show-word-limit
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeRepealWash">取消</el-button>
        <el-button
          style="margin-left: 10px"
          type="primary"
          :loading="buttonLoading"
          @click="enterRepealWash"
          >确定撤销</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  updateClothesSendBefore,
  clothesOperateRecord,
  listClothesOperateRecordDetais,
} from "@/api/clothes/order";
import { getLodop } from "@/print/LodopFuncs";
import { mapGetters } from "vuex";
import print from "@/api/print/print";

export default {
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      total: 0,
      type: 1,
      sendWashRecordLoading: false,
      tableData: [],
      dialogTableVisible: false,
      sendWashRecordDetailLoading: false,
      gridData: [],
      serialNum: "",
      factoryNum: null,
      seeDelData: {},
      repealWashVisible: false,
      reason: "",
      rowData: {},
      buttonLoading: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {
    this.factoryNum = this.$route.query.index;
    this.sendWashRecordPage();
  },
  methods: {
    //回退上一步状态(回退送洗之前的状态)
    withdrawClothesSendBefore(row) {
      this.repealWashVisible = true;
      this.rowData = { ...row };
    },
    // 获取送洗记录
    sendWashRecordPage() {
      this.q = true;
      this.sendWashRecordLoading = true;
      const params = new URLSearchParams();
      params.append("current", this.currentPage);
      params.append("size", this.pageSize);
      params.append("type", this.type);
      clothesOperateRecord(params).then((res) => {
        this.tableData = res.data.data.operateRecordList;
        // this.storeUserName = res.data.data.storeUserName
        // this.storeName = res.data.data.storeName
        this.total = res.data.data.total;
        this.sendWashRecordLoading = false;
      });
    },
    sendWashRecordDetail(row) {
      this.dialogTableVisible = true;
      this.sendWashRecordDetailLoading = true;
      // this.sendStoreUserName = row.storeUserName;
      listClothesOperateRecordDetais(row.serialNum).then((res) => {
        if (res.data.data == "null" || res.data.data == null) {
          this.dialogTableVisible = false;
          this.sendWashRecordPage();
          return false;
        }
        this.gridData = res.data.data.clothes;
        this.seeDelData = res.data.data;
        if (this.gridData.length > 0) {
          this.serialNum = this.gridData[0].serialNum;
        }
        this.sendWashRecordDetailLoading = false;
      });
    },
    closeRepealWash() {
      this.repealWashVisible = false;
      this.reason = "";
    },
    enterRepealWash() {
      if (this.reason == "") {
        this.$message.error("请输入撤销原因！");
        return;
      }
      this.buttonLoading = true;
      const params = new URLSearchParams();
      params.append("id", this.rowData.id);
      params.append("serialNum", this.rowData.serialNum);
      params.append("clothesNumber", this.rowData.clothesNumber);
      params.append("reason", this.reason);
      updateClothesSendBefore(params)
        .then((res) => {
          let resp = res.data;
          if (resp.code === 0) {
            this.$message({
              type: "success",
              message: resp.msg,
            });
            //刷新数据
            const params2 = new URLSearchParams();
            params2.append("current", this.currentPage);
            params2.append("size", this.pageSize);
            params2.append("type", this.type);
            clothesOperateRecord(params2).then((res) => {
              this.tableData = res.data.data.operateRecordList;
              this.total = res.data.data.total;
            });
            this.sendWashRecordDetail(this.rowData);
            this.closeRepealWash()
          } else {
            this.$message({
              type: "error",
              message: resp.msg,
            });
          }
            this.buttonLoading = false;
        })
        .catch(() => {
          this.buttonLoading = false;
        });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.sendWashRecordPage();
    },
    // 查询数据
    printPaper() {
      let LODOP = getLodop();
      LODOP.SET_LICENSES(
        "",
        "660FDFD7D9F7F7101A272F2EB57C306A",
        "C94CEE276DB2187AE6B65D56B3FC2848",
        ""
      );
      let data = {};
      let enclosure = this.gridData.filter((item) => {
        return item.clothesServiceFlag == "附";
      });
      data.title = "送洗";
      data.sendWashTime = this.$moment(this.seeDelData.operateTime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      data.flag = 1;
      data.factoryName = this.$route.query.factoryName;
      data.storeName = this.userInfo.storeName;
      data.serialNum = this.serialNum;
      data.enclosureNum = enclosure.length;
      data.storeUserName = this.userInfo.realName;
      data.dataLength = this.gridData.length;
      data.data = this.gridData;
      if (this.factoryNum == 1) {
        data.logistics = {
          blueBag: 0,
          grayBag: this.seeDelData.grayBag >= 0 ? this.seeDelData.grayBag : "",
          blackBag: 0,
          otherBag:
            this.seeDelData.otherBag >= 0 ? this.seeDelData.otherBag : "",
        };
      }
      if (this.factoryNum == 2) {
        data.logistics = {
          blueBag: this.seeDelData.blueBag >= 0 ? this.seeDelData.blueBag : "",
          grayBag: 0,
          blackBag:
            this.seeDelData.blackBag >= 0 ? this.seeDelData.blackBag : "",
          otherBag:
            this.seeDelData.otherBag >= 0 ? this.seeDelData.otherBag : "",
        };
      }
      data.remark = this.seeDelData.logisticsRemark;
      data.backWashNum = this.seeDelData.backWashNum;
      data.urgentNum = this.seeDelData.urgenstNum;
      data.singleNum = this.seeDelData.singleNum;
      console.log(data);
      print.printPaper(LODOP, data);
    },
  },
};
</script>

<style scoped lang="scss">
.el-button--default {
  border: 1px solid #dcdfe6;
}
</style>
