<template>
  <div class="takeClothesIndexView">
    <div class="topView" style="display: flex; align-items: center">
      <el-page-header
          style="width: 100%"
          @back="backPage"
          :content="'送洗(' + $route.query.factoryName + ')'"
      ></el-page-header>
      <el-button type="primary" class="btn-add" @click="sendWashRecordPage">
        送洗记录
      </el-button>
    </div>
    <div class="content">
      <el-row>
        <el-col :span="9">
          <not-wash
              :tableData="leftTable"
              :leftLoading="leftLoading"
              @sendWashData="sendWashData"
          ></not-wash>
        </el-col>
        <el-col :span="7">
          <div class="formBox">
            <div class="inputView">
              <el-input
                  v-model.trim="clothesNum"
                  size="medium"
                  ref="query"
                  placeholder="请输入条码号"
                  style="width: 60%; margin-right: 5px"
                  @keyup.enter.native="sendWashCenter"
              ></el-input>
              <el-button
                  type="primary"
                  size="medium"
                  @click="sendWashCenter"
                  :loading="sendWashLoading"
              >
                送 洗
              </el-button>
            </div>
            <h3 style="font-size: 18px">
              待揽收包裹数：{{ tableData ? tableData.length : 0 }}件
            </h3>

            <h3 class="setColor" style="font-size: 18px">
              <el-button type="primary" size="medium" @click="createdBundle"
              >新增包裹
              </el-button
              >
            </h3>
            <div class="packageTable">
              <Table
                  :border-bool="false"
                  :button-type="false"
                  :current-value="current"
                  :page="true"
                  :header-style="headerStyle"
                  :orientation="'center'"
                  :serial-number="true"
                  :table-data="tableData"
                  :loading="centerLoading"
                  :title-list="titleListData"
                  :total="total"
                  :height="'50vh'"
                  @rowData="rowData"
              />
            </div>
            <div class="btmFooter">
              <el-button
                  type="primary"
                  size="medium"
                  :loading="repairLoading"
                  @click="repairBundle"
                  v-if="cellRow.bundleState == 1"
              >
                补打包裹号
              </el-button>
              <el-button
                  type="danger"
                  size="medium"
                  @click="confirmSendWash"
                  :loading="confirmLoading"
                  :disabled="!rowId"
                  v-else
              >
                打包送洗并打印
              </el-button>
              <el-button
                  class="unpacking"
                  type="danger"
                  @click="cancelUnpacking"
                  :loading="unpackingLoading"
                  size="medium"
                  v-if="cellRow.oldBundleFlag == 1 && cellRow.bundleState == 0"
              >
                取消拆包
              </el-button>
              <el-button
                  class="unpacking"
                  type="danger"
                  @click="unpacking"
                  :loading="unpackingLoading"
                  size="medium"
                  :disabled="rowId == ''"
                  v-else
              >
                拆包
              </el-button>
              <el-button
                  class="cancelPackage"
                  type="danger"
                  @click="cancelPackage"
                  size="medium"
                  :disabled="cellRow.bundleState != 0"
              >
                取消包裹
              </el-button>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <already-wash :tableData="rightTable" @remove="remove"></already-wash>
        </el-col>
      </el-row>
    </div>

    <!-- 送洗记录 -->
    <transition name="userDetails">
      <div class="sendWashRecordView" v-if="q">
        <deliveryRecord @back="back"></deliveryRecord>
      </div>
    </transition>
    <!-- 选择袋子 -->
    <el-dialog :visible.sync="dialogTableVisible" width="600px">
      <template slot="title">
        <div style="text-align:center">添加袋子</div>
      </template>
      <div style="text-align:center;color:#C30E04;margin-bottom:20px">
        可用袋子数量: {{ bagText }}
      </div>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
      >
        <el-form-item label="袋子颜色" prop="bagId">
          <template slot="label">
            <span style="color:#101010">袋子颜色</span>
          </template>
          <el-select
              v-model="ruleForm.bagId"
              style="width:240px"
              clearable
              placeholder="请选择袋子"
          >
            <el-option
                v-for="item in bagOptions"
                :disabled="item.num == 0"
                :key="item.bagId"
                :label="item.colorUnitName"
                :value="item.bagId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="text-align:center;margin-top:20px">
        <el-button style="margin-right:20px;width:120px" @click="cancelButton"
        >取消
        </el-button
        >
        <el-button
            style="margin-left:20px;width:120px"
            @click="enterButton"
            type="primary"
            :loading="addLoading"
        >确定
        </el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryClothes,
  createdBundle,
  getStoreBundle,
  cancelBundleById,
  bundlePack,
  unpacking,
  getBundleClothes,
  saveClothesToDraft,
  repairBundle,
  reduceClothsToDraft,
  bundleReturnSave,
  cancelUnpacking,
  getLogisticsAvailableBags,
} from "@/api/clothes/order";
import {getStoreWash} from "@/api/clothes/clothes.js";
import {getLodop} from "@/print/LodopFuncs";
import {mapGetters} from "vuex";
import print from "@/api/print/print";
import deliveryRecord from "../components/deliveryRecord";
import NotWash from "../components/notSendWash";
import AlreadyWash from "../components/alreadySendWash";
import Table from "../components/table";

export default {
  name: "index",

  beforeRouteLeave(to, form, next) {
    if (this.rightTable.length > 0) {
      this.$confirm("送洗列表内含有未送洗的衣物,是否离开?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            if (
                this.cellRow.oldBundleFlag == null &&
                this.cellRow.bundleState == 0
            ) {
              this.bundleReturnSave();
            }
            next();
          })
          .catch(() => {
          });
    } else {
      if (this.cellRow.oldBundleFlag == null && this.cellRow.bundleState == 0) {
        this.bundleReturnSave();
      }
      next();
    }
  },
  data() {
    return {
      q: false,
      sendWashLoading: false,
      confirmLoading: false,
      factoryNum: null,
      clothesNum: "",
      blueBox: "",
      washClothesForm: {
        //洗衣工厂
        grayBag: "",
        otherBag: "",
      },
      extravagantForm: {
        blueBag: "",
        blackBag: "",
        otherBag: "",
      }, //奢护工厂

      logisticsRemark: "", //物流备注
      leftTable: [],
      rightTable: [],
      backWashNum: 0,
      urgentNum: 0,
      singleNum: 0,
      type: 1,
      gridData: [],
      serialNum: "",
      headerStyle: {
        background: "#FFF",
      },
      total: 0,
      current: 1,
      tableData: [],
      titleListData: [
        {
          prop: "bundleNumber",
          label: "包裹号",
          width: 120,
        },
        {
          prop: "washTime",
          label: "日期",
        },
        {
          prop: "bundleStateName",
          label: "包裹状态",
          width: 80,
        },
        {
          prop: "clothesCount",
          label: "件数",
        },
      ],
      currentRowId: "",
      //工厂门店信息
      information: {},
      addLoading: false,
      repairLoading: false,
      rowId: "",
      cellRow: {},
      disableButton: true,
      factoryId: "",
      leftLoading: false,
      centerLoading: false,
      unpackingLoading: false,
      recordList: [],
      dialogTableVisible: false,
      ruleForm: {
        bagId: "",
      },
      rules: {
        bagId: [{required: true, message: "请选择袋子", trigger: "change"}],
      },
      bagOptions: [],
      bagText: "",
    };
  },
  components: {
    deliveryRecord,
    NotWash,
    AlreadyWash,
    Table,
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {
    this.parameter();
    this.getStoreBundle();
    this.washNotList();
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.query.focus();
    });
  },

  methods: {
    parameter() {
      let user = this.$store.state.user.userInfo;
      let query = this.$route.query;
      this.information.storeId = user.storeId;
      this.information.storeName = user.storeName;
      this.information.factoryId = query.factoryId;
      this.information.factoryName = query.factoryName;
      this.factoryNum = query.index;
    },
    sendWashRecordPage() {
      this.q = true;
    },
    async washNotList() {
      this.leftLoading = true;
      const {data: res} = await getStoreWash(this.factoryNum);
      if (res.code != 0) return this.$message.error(res.msg);
      this.leftTable = res.data.clothesList;
      this.leftLoading = false;
      this.countPieces();
    },
    //去到送洗列表
    sendWashData(data) {
      this.rightTable.unshift(...data);
      data.forEach((item) => {
        this.leftTable.splice(this.leftTable.indexOf(item), 1);
      });
      this.backWash();
    },
    // 统计数量
    backWash() {
      this.backWashNum = 0;
      this.urgentNum = 0;
      this.singleNum = 0;
      this.rightTable.forEach((item) => {
        if (item.ifRewash && item.clothesServiceFlag !== "附") {
          this.backWashNum++;
        }
        if (
            item.clothesServiceName === "加急" &&
            item.clothesServiceFlag !== "附"
        ) {
          this.urgentNum++;
        }
        if (
            item.clothesServiceName === "单烫" &&
            item.clothesServiceFlag !== "附"
        ) {
          this.singleNum++;
        }
      });
    },
    notWash(data) {
      data.forEach((item) => {
        if (item.ifRewash && item.clothesServiceFlag !== "附") {
          this.backWashNum--;
        }
        if (
            item.clothesServiceName === "加急" &&
            item.clothesServiceFlag !== "附"
        ) {
          this.urgentNum--;
        }
        if (
            item.clothesServiceName === "单烫" &&
            item.clothesServiceFlag !== "附"
        ) {
          this.singleNum--;
        }
      });
    },
    async sendWashCenter() {
      if (Object.keys(this.cellRow).length == 0) {
        this.$message.error("当前送洗衣物还没创建包裹号,请先创建包裹号在送洗");
        this.clothesNum = "";
        this.$nextTick(() => {
          this.$refs.query.focus();
        });
        return false;
      }
      if (this.cellRow.bundleState != 0) {
        this.$message.error("当前包裹号不是装衣中状态，无法送洗");
        this.clothesNum = "";
        this.$nextTick(() => {
          this.$refs.query.focus();
        });
        return false;
      }
      this.sendWashLoading = true;
      if (this.clothesNum == "") {
        this.$message.warning("请输入条码号");
        this.sendWashLoading = false;
      } else {
        let existence = this.rightTable.find((item) => {
          return item.outClothesNum === this.clothesNum;
        });
        if (existence) {
          this.$message.error("已经扫描过了");
          this.clothesNum = "";
          this.$nextTick(() => {
            this.$refs.query.focus();
          });
          this.audio.btts("重复扫描");
          this.sendWashLoading = false;
          return;
        }
        const {data: res} = await queryClothes(
            this.type,
            this.clothesNum,
            this.rowId
        );
        console.log(res);
        if (res.code == 0) {
          if (this.leftTable == null || this.leftTable.length <= 0) {
            this.sendWashLoading = false;
            this.$message.error("此衣物不在送洗列表！");
            return;
          }
          let flag = true;
          this.audio.btts(res.data.clothesName);
          this.leftTable.forEach((item, index) => {
            if (item.outClothesNum === this.clothesNum) {
              this.rightTable.unshift(item);
              this.leftTable.splice(index, 1);
              this.clothesNum = "";
              this.$nextTick(() => {
                this.$refs.query.focus();
              });
              this.sendWashLoading = false;
              flag = false;
            }
          });
          if (flag) {
            //遍历完左边送洗列表还没找到匹配的衣物
            this.sendWashLoading = false;
            this.$message.error("此衣物不在送洗列表！");
            return;
          }
          this.saveClothesToDraft(res.data);
          this.backWash();
          this.countPieces();
        } else {
          this.clothesNum = "";
          this.$nextTick(() => {
            this.$refs.query.focus();
          });
          this.$message.error(res.msg);
          this.sendWashLoading = false;
          this.audio.btts("当前衣物不能送洗");
        }
      }
    },
    //装衣计算件数
    countPieces() {
      let data = this.tableData;
      this.tableData = data.map((item) => {
        if (item.bundleId == this.rowId && item.bundleState == 0) {
          item.clothesCount = this.rightTable.length;
        }
        return item;
      });
    },
    //移出
    remove(data) {
      this.leftTable.unshift(...data);
      data.forEach((item) => {
        this.rightTable.splice(this.rightTable.indexOf(item), 1);
      });
      this.notWash(data);
      this.reduceClothsToDraft(data);
      this.countPieces();
    },
    //打包送洗
    confirmSendWash() {
      if (this.factoryNum == 1) {
        // 普通工厂
        this.washClothes();
      }
      if (this.factoryNum == 2) {
        //奢护工厂
        this.extravagantFactory();
      }
    },
    // 普通工厂
    washClothes() {
      this.confirmLoading = true;
      this.$confirm("您将送洗衣物, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(async () => {
            this.rightTable.forEach((item) => {
              item.bundleId = this.rowId;
            });

            const {data: res} = await bundlePack({
              bundleId: this.rowId,
              clothesToSaveVoList: this.rightTable,
            });
            if (res.code == 0) {
              this.serialNum = res.data.serialNum;
              this.gridData = this.rightTable;
              this.rightTable = [];
              this.logisticsRemark = "";
              this.$message.success("送洗成功");
              this.confirmLoading = false;
              this.printPaper(res.data);
              this.getStoreBundle();
            } else {
              this.$message.error(res.msg);
              this.confirmLoading = false;
            }
          })
          .catch(() => {
            this.confirmLoading = false;
          });
    },
    //奢护工厂打包
    extravagantFactory() {
      this.confirmLoading = true;
      this.$confirm("您将送洗衣物, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(async () => {
            this.rightTable.forEach((item) => {
              item.bundleId = this.rowId;
            });
            const {data: res} = await bundlePack({
              bundleId: this.rowId,
              clothesToSaveVoList: this.rightTable,
            });
            if (res.code == 0) {
              this.serialNum = res.data.serialNum;
              this.gridData = this.rightTable;
              this.$message.success("送洗成功");
              if (this.gridData && this.gridData.length != 0) {
                this.printPaper(res.data);
              }
              this.getStoreBundle();
              this.rightTable = [];
              this.logisticsRemark = "";
              this.confirmLoading = false;
            } else {
              this.$message.error(res.msg);
              this.confirmLoading = false;
            }
          })
          .catch(() => {
            this.confirmLoading = false;
            this.$message({
              type: "info",
              message: "已取消送洗",
            });
          });
    },
    printPaper(data) {
      let LODOP = getLodop();
      LODOP.SET_LICENSES(
          "",
          "660FDFD7D9F7F7101A272F2EB57C306A",
          "C94CEE276DB2187AE6B65D56B3FC2848",
          ""
      );
      print.logisticsReceipt(LODOP, data);
    },
    back() {
      this.q = false;
    },
    //保存衣物到草稿箱
    async saveClothesToDraft(row) {
      if (this.cellRow.oldBundleFlag == 1) {
        let data = {
          bundleId: this.rowId,
          orderNumber: row.orderNumber,
          clothesNum: row.clothesNum,
          outClothesNum: row.outClothesNum,
          clothesName: row.clothesName,
          clothesServiceName: row.clothesServiceName,
        };
        const {data: res} = await saveClothesToDraft(data);
        console.log(res, "保存到草稿箱");
      }
    },
    //移除草稿箱
    async reduceClothsToDraft(row) {
      if (this.cellRow.oldBundleFlag == 1) {
        let data = row.map((item) => {
          item.bundleId = this.rowId;
          return item;
        });
        const {data: res} = await reduceClothsToDraft(data);
        console.log(res);
      }
    },
    //门店下已送洗、装衣中的包裹列表
    getStoreBundle() {
      let params = {
        storeId: this.information.storeId,
        factoryId: this.information.factoryId,
      };
      getStoreBundle(params).then((res) => {
        let data = res.data.data;
        let newList = {};
        if (Object.keys(data).length != 0) {
          this.tableData = data.filter((item) => {
            if (item.bundleState === 0) {
              newList = item;
              this.rowId = item.bundleId;
              this.cellRow = item;
            }
            return item.bundleState != 0;
          });
          if (Object.keys(newList).length != 0) {
            this.tableData.unshift(newList);
            this.getBundleClothes();
          }
        }
      });
    },
    //新增包裹
    createdBundle() {
      if (this.cellRow.bundleState == 0) {
        this.$message.error("包裹列表正在装衣中，无法新增包裹");
        return;
      }
      this.ruleForm = {
        bagId: "",
      };
      this.$refs.ruleForm?.resetFields();
      this.getLogisticsAvailableBagsList();
    },
    //  获取门店可用袋子数集合
    getLogisticsAvailableBagsList() {
      getLogisticsAvailableBags({factoryType: this.factoryNum}).then(
          ({data}) => {
            if (data.code == 0) {
              this.bagText = "";
              this.bagOptions = data.data.map((item) => {
                //不展示其它袋
                if (item.bagId != 0) {
                  this.bagText =
                      this.bagText + item.colorUnitName + item.num + "个、";
                }
                return item;
              });
              this.bagText = this.bagText.slice(0, this.bagText.length - 1);
              this.dialogTableVisible = true;
            } else {
              this.$message.error(data.msg);
            }
          }
      );
    },
    // 取消选择袋子
    cancelButton() {
      this.dialogTableVisible = false;
    },
    // 确定选择袋子
    enterButton() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.addLoading = true;
          createdBundle({...this.information, ...this.ruleForm}).then(
              (res) => {
                if (res.data.code == 0) {
                  let data = res.data.data;
                  this.tableData.unshift(data);
                  this.cellRow = data;
                } else {
                  this.$message.error(res.data.msg);
                }
                this.addLoading = false;
                this.dialogTableVisible = false;
              }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //取消包裹
    cancelPackage() {
      this.$confirm("此操作将永久删除该包裹, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            let params = {
              bundleId: this.rowId,
            };
            this.centerLoading = true;
            cancelBundleById(params).then((res) => {
              if (res.data.data) {
                let index = this.tableData.findIndex((item) => {
                  if (item.bundleId == this.rowId) {
                    return true;
                  }
                });
                this.tableData.splice(index, 1);
                this.rightTable = [];
                this.rowId = "";
                this.cellRow = {};
                this.washNotList();
                this.$message.success(res.data.msg);
              } else {
                this.$message.error(res.data.msg);
              }
              this.centerLoading = false;
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    //包裹拆包
    unpacking() {
      if (this.rowId == "") {
        this.$message.error("当前没选择包裹无法进行拆包操作");
        return false;
      }
      if (this.cellRow.bundleState == 0) {
        this.$message.error("当前包裹正在装衣中，不能进行拆包操作");
        return false;
      }
      this.$confirm("此操作将该包裹进行拆包, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            let params = {
              bundleId: this.rowId,
            };
            this.unpackingLoading = true;
            this.centerLoading = true;
            unpacking(params).then((res) => {
              if (res.data.code == 0) {
                this.$message.success("拆包成功");
                this.getStoreBundle();
                this.getBundleClothes();
              } else {
                this.$message.success("操作失败");
              }
              this.unpackingLoading = false;
              this.centerLoading = false;
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消拆包",
            });
          });
    },
    //取消拆包
    cancelUnpacking() {
      this.$confirm("此操作将该包裹取消拆包, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            let params = {
              bundleId: this.rowId,
            };
            this.unpackingLoading = true;
            this.centerLoading = true;
            cancelUnpacking(params).then((res) => {
              if (res.data.code == 0) {
                this.getStoreBundle();
                this.washNotList();
                this.rightTable = [];
                this.leftTable = [];
                this.recordList = [];
                this.rowId = "";
                this.cellRow = {};
                console.log(this.cellRow, "啥");
              } else {
                this.$message.error(res.data.msg);
              }
              console.log(res, "取消拆包成功");
            });
            this.unpackingLoading = false;
            this.centerLoading = false;
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消拆包",
            });
          });
    },
    //包裹id获取包裹下的衣物
    getBundleClothes() {
      let params = {
        bundleId: this.rowId,
      };
      getBundleClothes(params).then((res) => {
        let data = res.data.data;
        let dataList = data.dtoList.map((item) => {
          item.clothesServiceName = item.serviceName;
          return item;
        });
        this.recordList = data.recordList;
        this.rightTable = dataList ? dataList : [];
        this.eliminateData();
        this.countPieces();
      });
    },
    //新旧对比
    eliminateData() {
      let arrList = this.leftTable;
      this.leftTable = arrList.filter(
          (item) =>
              !this.rightTable.some(
                  (ele) => ele.outClothesNum === item.outClothesNum
              )
      );
      if (this.recordList.length > 0) {
        this.recordList.map((item) => {
          this.leftTable.push(item);
        });
      }
    },
    //补打包裹号
    repairBundle() {
      let params = {
        bundleId: this.cellRow.bundleId,
      };
      this.repairLoading = true;
      repairBundle(params).then((res) => {
        if (res.data.code == 0) {
          this.printPaper(res.data.data);
        } else {
          this.$message.error(res.data.msg);
        }
        this.repairLoading = false;
      });
    },
    //获取表格选择的id
    rowData(val, row) {
      this.rowId = val;
      this.cellRow = row;
    },
    backPage() {
      this.$router.go(-1);
    },
    //非旧包裹返回保存
    bundleReturnSave() {
      this.rightTable.forEach((item) => {
        item.bundleId = this.rowId;
      });
      let params = {
        bundleId: this.rowId,
        voList: this.rightTable,
      };
      bundleReturnSave(params).then(() => {
      });
    },
  },
};
</script>

<style scoped lang="scss">
.packageTable {
  margin: 10px;
  border: 1px solid #ebeef5;
}

.takeClothesIndexView {
  font-family: PingFangSC-Regular;

  > .topView {
    padding: 17px 20px;
    overflow: hidden;

    p {
      margin-top: 0;
      cursor: pointer;
    }

    > .tView {
      float: left;
      color: #333;
      text-align: left;
      font-size: 24px;
      font-family: PingFang-SC-Bold;
      line-height: 40px;
    }

    > .btn-add {
      float: right;
    }
  }
}

.el-form {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;

  ::v-deep .el-form-item__label {
    color: #e51d1d;
  }
}

.content {
  padding: 0 10px;

  .inputView {
    text-align: center;
  }

  h3 {
    text-align: center;
    font-size: 24px;
  }

  ul {
    display: flex;

    li {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      list-style: none;

      span {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  ::v-deep .el-textarea__inner {
    height: 100px !important;
  }

  .btmFooter {
    margin-top: 4%;
    text-align: center;

    .unpacking {
      background: #e1c51c;
      border: 1px solid #e1c51c;
      width: 100px;
    }

    .cancelPackage {
      background: #c30e04;
      border: 1px solid #c30e04;
    }
  }
}

.formBox {
  // background: #fff;
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 100%;
  // height: 50px;
  // padding: 10px 25px 0 25px;
  z-index: 100;
}

div {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::v-deep .el-dialog__body {
  padding-top: 0 !important;
}

.sendWashRecordView {
  position: fixed;
  left: 70px;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background: #fff;
  z-index: 100;
  padding: 60px 20px 25px 25px;
  overflow: auto;

  > .topView {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    padding: 20px 25px 0 25px;
    z-index: 100;

    > .backView {
      height: 30px;

      > .btn-back {
        font-size: 14px;
        color: #666;
        height: 30px;
        padding: 0;
        border: none;
        text-align: left;
      }
    }
  }
}

//动画过程
.userDetails-enter {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.userDetails-leave {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

.userDetails-enter-active {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.userDetails-leave-active {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

@keyframes fullscreen-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}
</style>
