<template>
  <div class="notSendWash">
    <!-- 未送洗 -->
    <div class="tableBox" v-loading="leftLoading">
      <div class="tableTitle">待送洗列表{{ tableData.length }}件</div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%;height:calc(100vh - 220px);overflow:auto"
      >
        <!-- <el-table-column type="selection" width="50"></el-table-column> -->
        <el-table-column label="序号" width="50" type="index"></el-table-column>
        <el-table-column
          label="订单号"
          prop="orderNumber"
          width="120"
        ></el-table-column>
        <el-table-column
          label="衣物号"
          prop="outClothesNum"
          width="120"
        > 
         <template slot-scope="scope">
          <span class="outClothesNum">{{scope.row.outClothesNum}} </span>
        </template>
        </el-table-column>
        <el-table-column
          label="衣物名称"
          show-overflow-tooltip
          prop="clothesName"
        >
          <template slot-scope="scope">
            <el-tag
              type="success"
              size="mini"
              v-if="scope.row.clothesServiceFlag === '附'"
              >附</el-tag
            >
            <el-tag type="warning" size="mini" v-else-if="scope.row.ifRewash"
              >返</el-tag
            >
            {{ scope.row.clothesName }}
          </template>
        </el-table-column>
        <el-table-column
          label="服务项目"
          prop="clothesServiceName"
        ></el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <!-- <el-button
        type="primary"
        :disabled="selectData.length == 0"
        @click="sendWash"
        >送洗</el-button
      > -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectData: [],
      tableHeight: 50,
    };
  },
  props: {
    tableData: {
      required: true,
      type: Array,
    },
    leftLoading:{
      type: Boolean,
      default: false
    }
  },
  mounted() {},
  methods: {
    // handleSelectionChange(val) {
    //   this.selectData = val;
    //   console.log(this.selectData);
    // },
    // sendWash() {
    //   this.$emit("sendWashData", this.selectData);
    // },
  },
};
</script>

<style scoped lang="scss">
::v-deep .cell {
  text-align: center;
}
::v-deep.el-table::before {
  /* 去除下边框 */
  height: 0;
}
.outClothesNum{
 -moz-user-select: none; /* Firefox私有属性 */
  -webkit-user-select: none; /* WebKit内核私有属性 */
  -ms-user-select: none; /* IE私有属性(IE10及以后) */
  -khtml-user-select: none; /* KHTML内核私有属性 */
  -o-user-select: none; /* Opera私有属性 */
  user-select: none; /* CSS3属性 */
}
.notSendWash {
  height: 100%;
 
}
.tableBox {
  height: 100%;
  border: 1px solid #ebeef5;
  .tableTitle {
    padding: 10px 0;
    border-bottom: 1px solid #ebeef5;
    text-align: center;
    font-weight: 700;
  }
}
.footer {
  text-align: right;
  margin-top: 20px;
  .el-button {
    width: 200px;
    margin-right: 15px;
  }
}
</style>
