<template>
  <div class="allForm">
    <el-form ref="formRef" :model="formData">
      <el-form-item
        v-for="item in searchData"
        style="display: flex"
        :key="item.prop"
      >
        <template slot="label">
          <span style="font-size: 14px; color: black;padding-left:20px">{{
            item.label ? item.label + ":" : ""
          }}</span>
        </template>
        <el-input
          v-if="item.type == 'text'"
          clearable
          :style="{ width: item.width + 'px' }"
          v-model.trim="formData[item.prop]"
          :placeholder="item.text ? item.text : '请输入' + item.label + item.tips"
          size="medium"
        />
        <el-select
          v-if="item.type == 'select'"
          :style="{ width: item.width + 'px' }"
          v-model="formData[item.prop]"
          clearable
          filterable
          :placeholder="'请选择' + item.label + item.tips"
          size="medium"
        >
          <el-option
            v-for="item2 in item.selectArr"
            :key="item2.id"
            :label="item2.name"
            :value="item2.id"
          />
        </el-select>
        <el-date-picker
          v-model="formData[item.prop]"
          :type="item.type"
          v-if="item.format == 'yyyy'"
          :value-format="item.format"
          :placeholder="'请选择' + item.label + item.tips"
        >
        </el-date-picker>
        <el-date-picker
         :picker-options="pickerOptions"
          clearable
          :style="{ width: item.width + 'px' }"
          v-if="item.type == 'DateTime'"
          v-model="formData[item.prop]"
          end-placeholder="结束日期"
          size="medium"
          start-placeholder="开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        />
        <el-button
          v-for="item3 in item.typeList"
          :key="item3.code"
          size="medium"
          :type="item3.type ? item3.type : ''"
          @click="queryInfo(item3.code)"
        >
          {{ item3.name }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    searchData: {
      type: Array,
      default: () => [],
    },
    defaultBool: {
      type: Boolean,
      default: false,
    },
    pickerOptions:{
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      formData: {},
    };
  },
  created() {
    this.setDefaultValue();
  },
  methods: {
    queryInfo(code) {
      if (code == "reset") {
        this.setDefaultValue();
        this.$emit("searchChange", { code: code });
      } else if (code == "resetData") {
        this.formData = {};
      } else {
        this.$emit("searchChange", { formData: this.formData, code: code });
      }
    },
    // 设置默认值
    setDefaultValue() {
      if (this.defaultBool) {
        let obj = {}
        this.searchData.forEach(item=>{
          if (item.value||item.value==0) {
           obj[item.prop]=item.value
          }
        })
        this.formData={...obj}
      }else{
        this.formData={}
      }
    },
  },
};
</script>

<style scoped lang="scss">
.el-form {
  display: flex;
  flex-wrap: wrap;
}
::v-deep .el-form-item {
  margin-bottom: 0;
}
::v-deep .el-form-item__label {
  font-size: 12px;
}

::v-deep .el-icon-search {
  height: 0;
}
::v-deep .el-form-item__label{
  padding: 0;
}
</style>
