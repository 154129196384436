import request from '@/utils/request'

/**
 * 根据门店id查询瑕疵订单列表
 */
export function getClothesCategoryList(params) {
    return request({
        url: '/factory/factoryConfirm/selectPageByStatusAndStoreId',
        method: 'get',
        params: params

    })
}


//表格查看数据
export const tableSeeData = (Id)=>{
	return request({
		method:"GET",
		url:`factory/factoryConfirm/selectOperationFirmById/${Id}`,
	})
}

//获取衣物详情

export const getClothesDetail = (outClothesNum)=>{
	return request({
		method:"GET",
		url:`factory/factoryConfirm/selectReminderFlawByClothesNum/${outClothesNum}`,
	})
}

//提交备注
export const postRemarks = (data)=>{
	return request({
		method:"PUT",
		url:"factory/factoryConfirm/factoryConfirmAndSend",
		data
	})
}

//获取工厂
export const getFactoryName = ()=>{
	return request({
		url:'sys/factory/getFactoryByStoreId',
		method:'GET'
	})
}
//门店分页查询瑕疵订单
export const selectPageByStatusAndStoreId = (params) => {
	return request({
		url: 'factory/factoryConfirm/selectPageByStatusAndStoreId/',
		method: 'get',
		params
	})
}
//门店催单状态列表统计
export const flawStatus = () => {
	return request({
		url: '/factory/factoryConfirm/store/status',
		method: 'get'
	})
}
//门店操作查看具体瑕疵信息
export const selectOperationFirmById = (id) => {
	return request({
		url: '/factory/factoryConfirm/store/selectOperationFirmById/' + id,
		method: 'get'
	})
}
//门店受理/修改瑕疵订单
export const modifyDefects = (data) => {
	return request({
		url: '/factory/factoryConfirm/accept',
		method: 'post',
		data
	})
}