<template>
  <div v-loading="loading" class="title">
    <el-table
      :border="borderBool"
      :cell-style="setCellStyle"
      :data="tableData"
      :header-cell-style="headerStyle"
      :stripe="stripe"
      :row-style="rowStyle"
      row-key="id"
      style="width: 100%;height:calc(100vh - 384px);overflow:auto"
      :tree-props="treeProps"
      @selection-change="handleSelectionChange"
      @row-click="changeCurrentRow"

    >
      <!-- 勾选 -->
      <el-table-column v-if="select" type="selection" width="55" />
      <!-- 序号 -->
      <el-table-column
        v-if="serialNumber"
        :align="orientation"
        label="序号"
        property="index"
        type="index"
        width="50"
      >
        <template slot-scope="scope">
          <span>{{ (currentValue - 1) * 10 + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <!-- 具体内容 -->
      <el-table-column
        v-for="item in titleList"
        :key="item.prop"
        :align="orientation"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
        :min-width="50"
        :show-overflow-tooltip="true"
      />
      <!-- 操作按钮 -->
      <el-table-column
        v-if="buttonType"
        :align="orientation"
        fixed="right"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-for="item in buttonType"
            :key="item.name"
            :size="item.size"
            :type="item.type"
            @click="buttonClick(scope, item.code)"
          >
            {{ item.name }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="!page" class="pageBox">
      <el-pagination
        :background="true"
        :current-page="currentValue"
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    titleList: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Boolean,
      default: false,
    },
    select: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    borderBool: {
      type: Boolean,
      default: false,
    },
    treeProps: {
      type: Object,
      default: () => ({}),
    },
    headerStyle: {
      type: Object,
      default: () => ({
        background: "#FFF",
      }),
    },
    height: {
      type: String,
      default: "100%",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    currentValue: {
      type: Number,
      default: 1,
    },
    orientation: {
      type: String,
      default: "left",
    },
    serialNumber: {
      type: Boolean,
      default: false,
    },
    stripe:{
      type: Boolean,
      default: false
    },
    buttonType: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentRowId: "",
    };
  },
  methods: {
    handleCurrentChange(value) {
      this.$emit("handleCurrentChange", value);
    },
    handleSelectionChange(data) {
      let arr = data.map((item) => item.storeId);
      this.$emit("selectChange", arr);
    },

    changeCurrentRow(row,column) {    
      if (this.currentRowId === row.bundleId) return;
      this.currentRowId = row.bundleId;
      this.tableData.forEach((item) => {
          if(item.bundleState == 0){
             this.$message.error('当前包裹正在装衣中，不能切换其他包裹')
          }
      })
    },
    rowStyle({ row }) {
      if(this.tableData.length > 0){
        this.tableData.forEach((item) => {
           if(item.bundleState == 0){
            this.currentRowId = item.bundleId
           }
        })
      }  
      if (this.currentRowId === row.bundleId) {
        this.$emit('rowData',this.currentRowId,row)
        // 此处返回选中行的样式对象，按需设置
        return {
          "background-color": "#efbfbd",
          color: "#000",
        };
      }
  
      // console.log(999)
    },
    // 点击操作按钮出发
    buttonClick(data, code) {
      this.$emit("operationClick", { row: data.row, code: code });
    },
    setCellStyle({ column, row }) {},
  },
};
</script>
<style>
.el-tooltip__popper {
  font-size: 14px;
  max-width: 25%;
}
</style>
<style scoped lang="scss">
.pageBox {
  margin-top: 10px;
  float: right;
}
::v-deep .el-table th.el-table__cell > .cell {
  color: rgba(16, 16, 16, 100);
  font-size: 14px !important;
}

::v-deep .el-table .cell{
  padding: 0 5px;
}
/deep/ .el-table tr {
  cursor: pointer;
}
.el-table::before{
  height: 0;
}
/deep/ .el-table tbody tr:hover > td {
  // background-color: rebeccapurple !important;s
  // color: #ffffff;
 
  // 可以选择隐藏
  background-color: transparent !important;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
      background-color: #efbfbd;
    }
    ::v-deep .el-table tbody tr:hover>td {
        background-color: transparent !important;
    }
</style>
