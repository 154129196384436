<template>
  <div class="notSendWash">
    <!-- 未送洗 -->
    <div class="tableBox">
      <div class="tableTitle">送洗列表{{ tableData ? tableData.length : 0 }}件</div>
      <el-table
        ref="table"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%;height:calc(100vh - 220px);overflow:auto"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="序号" width="50" type="index"></el-table-column>
        <el-table-column
          label="订单号"
          width="120"
          prop="orderNumber"
        ></el-table-column>
        <el-table-column
          label="衣物号"
          width="120"
          prop="outClothesNum"
        ></el-table-column>
        <el-table-column label="衣物名称" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag
              type="success"
              size="mini"
              v-if="scope.row.clothesServiceFlag === '附'"
              >附</el-tag
            >
            <el-tag type="warning" size="mini" v-else-if="scope.row.ifRewash"
              >返</el-tag
            >
            {{ scope.row.clothesName }}
          </template>
        </el-table-column>
        <el-table-column
          label="服务项目"
          prop="clothesServiceName"
        ></el-table-column>
      </el-table>
    </div>
    <el-button
      type="primary"
      class="remove-button"
      :disabled="selectData.length == 0"
      @click="removeWash"
      >移出送洗</el-button
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectData: [],
      tableHeight: 50,
    };
  },
  props: {
    tableData: {
      required: true,
      type: Array,
    },
  },
  mounted() {},
  methods: {
    handleSelectionChange(val) {
      this.selectData = val;
    },
    removeWash() {
      this.$emit("remove", this.selectData);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .cell {
  text-align: center;
}
::v-deep.el-table::before {
  /* 去除下边框 */
  height: 0;
}
.tableBox {
  border: 1px solid #ebeef5;
  .tableTitle {
    padding: 10px 0;
    border-bottom: 1px solid #ebeef5;
    text-align: center;
    font-weight: 700;
  }
}
.remove-button {
  width: 150px;
  margin-right: 15px;
  position: absolute;
  top: -57px;
  right: 100px;
}
</style>
