<template>
    <div class="deliveryRecord">
        <div v-loading="loading">
            <el-row class="tab" :gutter="20" style="padding: 20px">
                <el-col :span="8">
                    <el-page-header content="送洗记录" @back="back"/>
                </el-col>
            </el-row>
            <Search
                    :search-data="searchData"
                    :loading="loading"
                    :pickerOptions="pickerOptions"
                    @searchChange="searchChange"
                    :defaultBool="true"
            />
            <div style="padding: 0 20px">
                <el-row :gutter="20">
                    <el-col
                            style="padding: 15px;font-size: 14px;color:#000;font-weight:700"
                    >
                        <span>送洗包裹：</span>
                        <span
                        >{{
                            titleStatistics.bundleCount ? titleStatistics.bundleCount : "0"
                            }}个</span
                        >
                        <span style="padding-left:20px">送洗衣物：</span>
                        <span
                        >{{
                            titleStatistics.allClothesCount
                                ? titleStatistics.allClothesCount
                                : "0"
                            }}件</span
                        >
                    </el-col>
                </el-row>
                <Table
                        :stripe="true"
                        :border-bool="false"
                        :button-type="true"
                        :current-value="queryCondition.current"
                        :header-style="headerStyle"
                        :orientation="'center'"
                        :serial-number="true"
                        :height="'62vh'"
                        :table-data="tableData"
                        :title-list="titleListData"
                        :total="total"
                        @handleCurrentChange="handleCurrentChange"
                        @operationClick="operationClick"
                />
            </div>
            <transition name="userDetails">
                <div class="sendWashRecordView" v-if="drawer">
                    <Details
                            @close="close"
                            :buttonDisplay="buttonDisplay"
                            :height="height"
                            :length="length"
                            :operateType="operateType"
                            :dataList="dataList"
                            :imageList="imageList"
                            :viewSwitch="viewSwitch"
                            @switchClose="switchClose"
                    >
                        <el-col :span="4">
                            <span>包裹号：</span>
                            <span>{{ dataList.bundleNumber }}</span>
                        </el-col>
                        <el-col :span="3">
                            <span>送洗工厂：</span>
                            <span>{{ dataList.factoryName }}</span>
                        </el-col>
                        <el-col :span="5">
                            <span>送洗时间：</span>
                            <span>{{ dataList.washTime }}</span>
                        </el-col>
                        <el-col :span="4">
                            <span>送洗人：</span>
                            <span>{{ dataList.washUserName }}</span>
                        </el-col>
                        <el-col :span="4">
                            <span>包裹状态：</span>
                            <span>{{ dataList.bundleStateDesc }}</span>
                        </el-col>
                        <el-col :span="3" style="display: flex">
                            <span>包裹图片：</span>
                            <el-button type="text" style="margin-top: -2px" @click="seeClick"
                            >查看
                            </el-button
                            >
                        </el-col>
                        <el-col :span="3">
                            <span>包裹颜色：</span>
                            <span>{{ dataList.bagName }}</span>
                        </el-col>
                    </Details>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import Search from "./components/search";
import Table from "@/components/table2";
import Details from "@/components/logisticsDetails/index.vue";
import {
    getBundleWashRecordPage,
    packageDetails,
    getBundleStatusMap,
    getWashStatistics,
} from "@/api/clothes/order";
import {getFactoryName} from "@/api/factory/factoryConfirm.js";

export default {
    name: "",
    components: {
        Search,
        Table,
        Details,
    },
    data() {
        return {
            searchData: [
                {
                    prop: "DateTime",
                    type: "DateTime",
                    width: 300,
                },
                {
                    prop: "incomingStatus",
                    tips: "入厂状态",
                    label: "",
                    type: "select",
                    selectArr: [
                        {
                            id: 1,
                            name: "未入厂",
                        },
                        {
                            id: 2,
                            name: "已入厂",
                        },
                        {
                            id: 3,
                            name: "已出厂",
                        },
                    ],
                    width: 180,
                },
                {
                    prop: "bundleState",
                    label: "",
                    tips: "包裹状态",
                    type: "select",
                    selectArr: [
                        {
                            id: "",
                            name: "",
                        },
                    ],
                    width: 180,
                },
                {
                    prop: "factoryId",
                    label: "",
                    tips: "送洗工厂",
                    type: "select",
                    selectArr: [],
                    width: 180,
                },
                {
                    prop: "queryNumber",
                    label: "",
                    tips: "包裹号/衣物号",
                    type: "text",
                    width: 180,
                },
                {
                    typeList: [
                        {
                            name: "查询",
                            code: "search",
                            type: "primary",
                        },
                        {
                            name: "重置",
                            code: "reset",
                            type: "primary",
                        },
                    ],
                },
                (this.loading = false),
            ],
            headerStyle: {
                fontSize: "14px",
                background: "#EEF0F1",
                color: "#363636",
            },
            titleListData: [
                {
                    prop: "bundleNumber",
                    label: "包裹号",
                },
                {
                    prop: "bagName",
                    label: "袋子名称",
                },
                {
                    prop: "washTime",
                    label: "送洗日期",
                },
                {
                    prop: "clothesCount",
                    label: "送洗件数",
                },
                {
                    prop: "factoryName",
                    label: "送洗工厂",
                },
                {
                    prop: "bundleStateName",
                    label: "包裹状态",
                },
                {
                    prop: "incomingStatusName",
                    label: "入厂状态",
                },
            ],
            tableData: [],
            dataList: {},
            imageList: [],
            titleStatistics: {},
            queryCondition: {
                current: 1,
                size: 10,
                factoryPageFlag: 2,
                beginTime: "",
                endTime: "",
                incomingStatus: 1,
            },
            total: 0,
            current: 1,
            loading: false,
            drawer: false,
            direction: "rtl",
            viewSwitch: false,
            buttonDisplay: true,
            height: "",
            length: null,
            pickerOptions: {
                shortcuts: [
                    {
                        text: "昨天",
                        onClick(picker) {
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24);
                            picker.$emit("pick", [start, start]);
                        },
                    },
                    {
                        text: "今天",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近一周",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近一个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近三个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                ],
                onPick: ({maxDate, minDate}) => {
                    this.selectDate = minDate.getTime();
                    if (maxDate) {
                        this.selectDate = "";
                    }
                },
                disabledDate: (time) => {
                    if (this.selectDate !== "") {
                        const one = 90 * 24 * 3600 * 1000;
                        const minTime = this.selectDate - one;
                        const maxTime = this.selectDate + one;
                        return (
                            time.getTime() < minTime ||
                            time.getTime() > maxTime ||
                            time.getTime() > Date.now() - 8.64e6
                        );
                    }
                },
            },
            DateTime: [],
            operateType: 6
        };
    },
    created() {
        this.lastWeek();
        this.getBundleWashRecordPage();
        this.factoryName();
        this.getBundleStatusMap();
        this.getWashStatistics();
    },
    methods: {
        lastWeek() {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            this.DateTime = [this.formatTime(start), this.formatTime(end)];
            this.queryCondition.beginTime = this.DateTime[0];
            this.queryCondition.endTime = this.DateTime[1];
            this.searchData[0].value = this.DateTime;
            this.searchData[1].value = 1;
        },
        //格式化时间
        formatTime(param) {
            let y = param.getFullYear();
            let m = param.getMonth() + 1;
            let d = param.getDate();
            m = m < 10 ? "0" + m : m;
            d = d < 10 ? "0" + d : d;
            return y + "-" + m + "-" + d + " ";
        },
        //查询送洗记录列表
        getBundleWashRecordPage() {
            this.loading = true;
            getBundleWashRecordPage(this.queryCondition).then((res) => {
                let data = res.data.data;
                this.tableData = data.records.map((item) => {
                    item.buttonList = [
                        {
                            name: "查看",
                            size: "medium",
                            type: "text",
                            code: "details",
                        },
                    ];
                    return item;
                });
                this.total = data.total;
                this.loading = false;
                console.log(res, "送洗记录");
            });
        },
        async factoryName() {
            const {data: res} = await getFactoryName();
            if (res.code != 0) return this.$message.error(res.msg);
            this.searchData[3].selectArr = res.data.map((item) => {
                item.id = item.factoryType;
                item.name = item.washFactoryName;
                return item;
            });
        },
        searchChange({code, formData}) {
            if (code == "search") {
                this.queryCondition = {
                    ...this.queryCondition,
                    ...formData,
                };
                this.queryCondition.beginTime = this.queryCondition.DateTime
                    ? this.queryCondition.DateTime[0]
                    : "";
                this.queryCondition.endTime = this.queryCondition.DateTime
                    ? this.queryCondition.DateTime[1]
                    : "";
                this.$delete(this.queryCondition, "DateTime");
            } else if (code == "reset") {
                this.queryCondition = {
                    current: 1,
                    size: 10,
                    factoryPageFlag: 2,
                    beginTime: "",
                    endTime: "",
                    incomingStatus: 1,
                };
            }
            this.getWashStatistics();
            this.getBundleWashRecordPage();
        },
        //获取包裹状态
        getBundleStatusMap() {
            getBundleStatusMap().then((res) => {
                let data = Object.values(res.data.data);
                let arrList = [];
                data.forEach((item, index) => {
                    arrList.push({
                        id: index,
                        name: item,
                    });
                });
                this.searchData[2].selectArr = arrList;
            });
        },
        //包裹详情
        packageDetails(row) {
            packageDetails(row.bundleNumber, 4).then((res) => {
                let data = res.data.data;
                this.dataList = data;
                let num = data.trajectoryList.length;
                this.calculationHeight(num);
                this.imageList = data.bundlePictureList ? data.bundlePictureList : [];
                this.dataList.bundleClothesDetailList = data.bundleClothesDetailList.map(
                    (item) => {
                        item.buttonList = [
                            {
                                name: "查看",
                                size: "medium",
                                type: "text",
                                code: "details",
                                width: "180",
                            },
                        ];
                        if (item.couponId != null && item.couponId !== 0) {
                            item.couponId = "已使用";
                        } else {
                            item.couponId = "未使用";
                        }
                        if (!item.accessoryNumber) {
                            item.orderOriginalPrice =
                                item.originalPrice + "元/ " + item.currentPrice + "元";
                        } else {
                            item.orderOriginalPrice = "0元";
                        }
                        if (item.squareNum) {
                            item.squareNum = item.squareNum + "m²";
                        } else {
                            item.squareNum = "0m²";
                        }
                        return item;
                    }
                );
            });
        },
        calculationHeight(num) {
            let high = 45;
            switch (num) {
                case 1:
                    high = 41;
                    break;
                case 2:
                    high = 32;
                    break;
                case 3:
                    high = 37;
                    break;
                case 4:
                    high = 40;
                    break;
                case 5:
                    high = 41;
                    break;
                case 6:
                    high = 43;
                    break;
            }
            this.length = num - 1;
            console.log(this.length, '几')
            this.height = high * num;
        },
        //获取送洗包裹统计数据
        getWashStatistics() {
            getWashStatistics(this.queryCondition).then((res) => {
                this.titleStatistics = res.data.data;
            });
        },
        //表格按钮
        operationClick({row, code}) {
            console.log(row, code);
            this.drawer = true;
            this.packageDetails(row);
        },
        //分页
        handleCurrentChange(val) {
            this.queryCondition.current = val;
            this.getBundleWashRecordPage();
        },
        back() {
            this.$emit("back");
        },
        seeClick() {
            this.viewSwitch = true;
        },
        switchClose() {
            this.viewSwitch = false;
        },
        close() {
            this.drawer = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.deliveryRecord {
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 10px 25px 0 25px;
  z-index: 100;
}

::v-deep .el-drawer {
  width: 100% !important;
}

.sendWashRecordView {
  position: fixed;
  left: 70px;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background: #fff;
  z-index: 100;
  padding: 20px 20px 25px 25px;
  overflow: auto;

  > .topView {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    padding: 20px 25px 0 25px;
    z-index: 100;

    > .backView {
      height: 30px;

      > .btn-back {
        font-size: 14px;
        color: #666;
        height: 30px;
        padding: 0;
        border: none;
        text-align: left;
      }
    }
  }
}

//动画过程
.userDetails-enter {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.userDetails-leave {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

.userDetails-enter-active {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.userDetails-leave-active {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

@keyframes fullscreen-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}
</style>
