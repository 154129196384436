<template>
    <div class="takeClothesIndexView" >
      <div class="topView" style="display: flex; align-items: center;">
        <el-page-header
          style="width: 100%;"
          @back="$router.go(-1)"
          :content="'送洗(' + $route.query.factoryName + ')'"
        ></el-page-header>
        <el-button type="primary" class="btn-add" @click="sendWashRecordPage">
          送洗记录
        </el-button>
      </div>
      <div class="content">
        <el-row>
          <el-col :span="9">
            <not-wash
              :tableData="leftTable"
              @sendWashData="sendWashData"
            ></not-wash>
          </el-col>
          <el-col :span="6">
            <div class="formBox">
              <div class="inputView">
                <el-input
                  v-model.trim="clothesNum"
                  size="medium"
                  ref="query"
                  placeholder="请输入条码号"
                  style="width: 60%; margin-right: 5px;"
                  @keyup.enter.native="sendWashCenter"
                ></el-input>
                <el-button
                  type="primary"
                  size="medium"
                  @click="sendWashCenter"
                  :loading="sendWashLoading"
                >
                  送 洗
                </el-button>
              </div>
              <h3 style="font-size:18px">总件数：{{ rightTable.length }}件</h3>
              <ul>
                <li>
                  <span>返洗</span>
                  <span>{{ backWashNum }}件</span>
                </li>
                <li>
                  <span>加急</span>
                  <span>{{ urgentNum }}件</span>
                </li>
                <li>
                  <span>单烫</span>
                  <span>{{ singleNum }}件</span>
                </li>
              </ul>
              <h3 class="setColor" style="font-size:18px">物流信息(必填)</h3>

              <el-form
                :model="extravagantForm"
                :rules="extravagantRules"
                ref="extravagantRef"
                label-width="100px"
                class="demo-ruleForm"
                v-if="factoryNum == 2"
              >
                <el-form-item label="蓝色物流箱:" prop="blueBag">
                  <el-select
                    v-model="extravagantForm.blueBag"
                    size="small"
                    clearable
                    placeholder="请选择数量"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="黑色包装袋:" prop="blackBag">
                  <el-select
                    v-model="extravagantForm.blackBag"
                    size="small"
                    clearable
                    placeholder="请选择数量"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="其他包装袋:" prop="otherBag">
                  <el-select
                    v-model="extravagantForm.otherBag"
                    size="small"
                    clearable
                    placeholder="请选择数量"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-form>

              <el-form
                :model="washClothesForm"
                :rules="washClothesRules"
                ref="washClothesRef"
                label-width="100px"
                class="demo-ruleForm"
                v-if="factoryNum == 1"
              >
                <el-form-item label="灰色收纳袋:" prop="grayBag">
                  <el-select
                    v-model="washClothesForm.grayBag"
                    size="small"
                    clearable
                    placeholder="请选择数量"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="其他包装袋:" prop="otherBag">
                  <el-select
                    v-model="washClothesForm.otherBag"
                    size="small"
                    clearable
                    placeholder="请选择数量"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-form>

              <h3 style="margin:10px 0 20px 0;font-size:18px">店长送洗说明</h3>
              <div class="remarkBox">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 6 }"
                  placeholder="请输入内容"
                  clearable
                  v-model="logisticsRemark"
                  resize="none"
                  style="width: 80%; height: 100px;"
                  show-word-limit
                  maxlength="50"
                ></el-input>
              </div>
              <div class="btmFooter">
                <el-button
                  type="danger"
                  @click="confirmSendWash"
                  :loading="confirmLoading"
                >
                  确认送洗并打印
                </el-button>
              </div>
            </div>
          </el-col>
          <el-col :span="9">
            <already-wash :tableData="rightTable" @remove="remove"></already-wash>
          </el-col>
        </el-row>
      </div>

      <!-- 送洗记录 -->
      <transition name="userDetails">
        <div class="sendWashRecordView" v-if="q" >
          <div class="topView">
            <div class="backView">
              <el-page-header
                @back="q = false"
                content="送洗记录"
              ></el-page-header>
            </div>
          </div>
          <wash-record></wash-record>
        </div>
      </transition>
    </div>
  </template>

  <script>
  import { queryClothes, operate } from '@/api/clothes/order'
  import { getStoreWash } from '@/api/clothes/clothes.js'
  import { getLodop } from '@/print/LodopFuncs'
  import { mapGetters } from 'vuex'
  import print from '@/api/print/print'
  import WashRecord from '../components/sendWashRecord'
  import NotWash from '../components/notSendWash'
  import AlreadyWash from '../components/alreadySendWash'

  export default {
    name: 'index',

    beforeRouteLeave(to, form, next) {
      if (this.rightTable.length > 0) {
        this.$confirm('送洗列表内含有未送洗的衣物,是否离开?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            next()
          })
          .catch(() => {})
      } else {
        next()
      }
    },
    data() {
      return {
        q: false,
        sendWashLoading: false,
        confirmLoading: false,
        factoryNum: null,
        clothesNum: '',
        blueBox: '',
        washClothesForm: {
          //洗衣工厂
          grayBag: '',
          otherBag: '',
        },
        washClothesRules: {
          grayBag: [
            { required: true, message: '请选择灰色袋子', trigger: 'change' },
          ],
          otherBag: [
            { required: true, message: '请选择其他包装袋', trigger: 'change' },
          ],
        },
        extravagantForm: {
          blueBag: '',
          blackBag: '',
          otherBag: '',
        }, //奢护工厂
        extravagantRules: {
          blueBag: [
            { required: true, message: '请选择活动区域', trigger: 'change' },
          ],
          blackBag: [
            { required: true, message: '请选择活动区域', trigger: 'change' },
          ],
          otherBag: [
            { required: true, message: '请选择活动区域', trigger: 'change' },
          ],
        },
        logisticsRemark: '', //物流备注
        options: [
          { value: 0, text: '0个' },
          { value: 1, text: '1个' },
          { value: 2, text: '2个' },
          { value: 3, text: '3个' },
          { value: 4, text: '4个' },
          { value: 5, text: '5个' },
          { value: 6, text: '6个' },
          { value: 7, text: '7个' },
          { value: 8, text: '8个' },
          { value: 9, text: '9个' },
          { value: 10, text: '10个' },
        ],
        leftTable: [],
        rightTable: [],
        backWashNum: 0,
        urgentNum: 0,
        singleNum: 0,
        type: 1,
        gridData: [],
        serialNum: '',
      }
    },
    components: {
      WashRecord,
      NotWash,
      AlreadyWash,
    },
    computed: {
      ...mapGetters(['userInfo']),
    },
    created() {
      this.factoryNum = this.$route.query.index
      this.washNotList()
    },
    mounted() {
      this.$nextTick(() => {
        this.$refs.query.focus()
      })
    },
    methods: {
      sendWashRecordPage() {
        this.q = true
      },
      async washNotList() {
        const { data: res } = await getStoreWash(this.factoryNum)
        console.log(res)
        if (res.code != 0) return this.$message.error(res.msg)
        this.leftTable = res.data.clothesList
      },
      //去到送洗列表
      sendWashData(data) {
        this.rightTable.unshift(...data)
        data.forEach((item) => {
          this.leftTable.splice(this.leftTable.indexOf(item), 1)
        })
        this.backWash()
      },
      // 统计数量
      backWash() {
        this.backWashNum = 0
        this.urgentNum = 0
        this.singleNum = 0
        this.rightTable.forEach((item) => {
          if (item.ifRewash && item.clothesServiceFlag !== '附') {
            this.backWashNum++
          }
          if (
            item.clothesServiceName === '加急' &&
            item.clothesServiceFlag !== '附'
          ) {
            this.urgentNum++
          }
          if (
            item.clothesServiceName === '单烫' &&
            item.clothesServiceFlag !== '附'
          ) {
            this.singleNum++
          }
        })
      },
      notWash(data) {
        data.forEach((item) => {
          if (item.ifRewash && item.clothesServiceFlag !== '附') {
            this.backWashNum--
          }
          if (
            item.clothesServiceName === '加急' &&
            item.clothesServiceFlag !== '附'
          ) {
            this.urgentNum--
          }
          if (
            item.clothesServiceName === '单烫' &&
            item.clothesServiceFlag !== '附'
          ) {
            this.singleNum--
          }
        })
      },
      async sendWashCenter() {
        this.sendWashLoading = true
        if (this.clothesNum == '') {
          this.$message.warning('请输入条码号')
          this.sendWashLoading = false
        } else {
          let existence = this.rightTable.find((item) => {
            return item.outClothesNum === this.clothesNum
          })
          if (existence) {
            this.$message.error('已经扫描过了')
            this.clothesNum = ''
            this.$nextTick(() => {
              this.$refs.query.focus()
            })
            this.audio.btts('重复扫描')
            this.sendWashLoading = false
            return
          }
          const { data: res } = await queryClothes(this.type, this.clothesNum)
          console.log(res)
          if (res.code == 0 ) {
            if (this.leftTable == null || this.leftTable.length <= 0) {
              this.sendWashLoading = false
              this.$message.error("此衣物不在送洗列表！")
              return
            }
            this.audio.btts(res.data.clothesName)
            let flag = true;
            this.leftTable.forEach((item, index) => {
              if (item.outClothesNum === this.clothesNum) {
                this.rightTable.unshift(item)
                this.leftTable.splice(index, 1)
                this.clothesNum = ''
                this.$nextTick(() => {
                  this.$refs.query.focus()
                })
                this.sendWashLoading = false
                flag = false;
              }
            })
            if (flag) {
              //遍历完左边送洗列表还没找到匹配的衣物
              this.sendWashLoading = false
              this.$message.error("此衣物不在送洗列表！")
              return
            }
            this.backWash()
          } else {
            this.clothesNum = ''
            this.$nextTick(() => {
              this.$refs.query.focus()
            })
            this.$message.error(res.msg)
            this.sendWashLoading = false
            this.audio.btts('当前衣物不能送洗')
          }
        }
      },
      //移出
      remove(data) {
        this.leftTable.unshift(...data)
        data.forEach((item) => {
          this.rightTable.splice(this.rightTable.indexOf(item), 1)
        })
        this.notWash(data)
      },

      confirmSendWash() {
        if (this.rightTable.length == 0)
          return this.$message.warning('无衣物送洗')
        if (this.factoryNum == 1) {
          // 普通工厂
          this.washClothes()
        }
        if (this.factoryNum == 2) {
          //奢护工厂
          this.extravagantFactory()
        }
      },
      washClothes() {
        var _that = this
        this.confirmLoading = true
        this.$refs.washClothesRef.validate((valid) => {
          if (valid) {
            this.$confirm('您将送洗衣物, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }).then(async () => {
              let datas = []
              this.rightTable.forEach((item) => {
                let data = {
                  id: item.id, // 衣物主键Id
                  clothesNumber: item.clothesNum, // 衣物编号
                  orderNumber: item.orderNumber, //订单编号
                }
                datas.push(data)
              })
              let dd = {
                clothesList: datas,
                type: this.type,
                logisticsRemark: this.logisticsRemark,
                ...this.washClothesForm,
              }
              const { data: res } = await operate(dd)
              console.log(res)
              if (res.code == 0) {
                this.serialNum = res.data.serialNum
                this.gridData = this.rightTable
                this.$message.success('送洗成功')
                this.$nextTick(() => {
                  _that.$refs.washClothesRef.resetFields()
                })
                this.printPaper()
                this.rightTable = []
                this.logisticsRemark = ''
                this.confirmLoading = false
              } else {
                this.$message.error(res.msg)
                this.confirmLoading = false
              }
            }).catch(() => {
              this.confirmLoading = false
              this.$message({
                type: 'info',
                message: '已取消送洗'
              });
            });
          } else {
            this.confirmLoading = false
            console.log('error submit!!')
            return false
          }
        })
      },
      extravagantFactory() {
        this.confirmLoading = true
        this.$refs.extravagantRef.validate((valid) => {
          if (valid) {
            this.$confirm('您将送洗衣物, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }).then(async () => {
              let datas = []
              this.rightTable.forEach((item) => {
                let data = {
                  id: item.id, // 衣物主键Id
                  clothesNumber: item.clothesNum, // 衣物编号
                  orderNumber: item.orderNumber, //订单编号
                }
                datas.push(data)
              })
              let dd = {
                clothesList: datas,
                type: this.type,
                logisticsRemark: this.logisticsRemark,
                ...this.extravagantForm,
              }
              const { data: res } = await operate(dd)
              if (res.code == 0) {
                this.serialNum = res.data.serialNum
                this.gridData = this.rightTable
                this.$message.success('送洗成功')
                this.$nextTick(() => {
                  _that.$refs.extravagantRef.resetFields()
                })
                this.printPaper()
                this.rightTable = []
                this.logisticsRemark = ''
                this.confirmLoading = false
              } else {
                this.$message.error(res.msg)
                this.confirmLoading = false
              }
            })
          } else {
            this.confirmLoading = false
            console.log('error submit!!')
            return false
          }
        })
      },
      printPaper() {
        let LODOP = getLodop()

        // try {
        //   let LODOP = getLodop()
        //   if (LODOP.VERSION) {
        //     if (LODOP.CVERSION) {
        //       //执行打印操作
        //       alert('打印嗷嗷嗷')
        //       this.CreatePage()
        //     } else {
        //       alert('本机已成功安装了Lodop控件！\n 版本号:' + LODOP.VERSION)
        //     }
        //   }
        // } catch (err) {
        //   console.log(err)
        // }
        LODOP.SET_LICENSES(
          '',
          '660FDFD7D9F7F7101A272F2EB57C306A',
          'C94CEE276DB2187AE6B65D56B3FC2848',
          '',
        )
        let data = {}
        let enclosure = this.rightTable.filter((item) => {
          return item.clothesServiceFlag == '附'
        })
        data.title = '送洗'
        data.factoryName = this.$route.query.factoryName
        data.storeName = this.userInfo.storeName
        data.serialNum = this.serialNum
        data.storeUserName = this.userInfo.realName
        data.dataLength = this.gridData.length
        data.data = this.gridData
        data.enclosureNum = enclosure.length
        data.backWashNum = this.backWashNum
        data.urgentNum = this.urgentNum
        data.singleNum = this.singleNum
        if (this.factoryNum == 1) {
          data.logistics = {
            blueBag: 0,
            grayBag:
              this.washClothesForm.grayBag >= 0
                ? this.washClothesForm.grayBag
                : '',
            blackBag: 0,
            otherBag:
              this.washClothesForm.otherBag >= 0
                ? this.washClothesForm.otherBag
                : '',
          }
        }
        if (this.factoryNum == 2) {
          data.logistics = {
            blueBag:
              this.extravagantForm.blueBag >= 0
                ? this.extravagantForm.blueBag
                : '',
            grayBag: 0,
            blackBag:
              this.extravagantForm.blackBag >= 0
                ? this.extravagantForm.blackBag
                : '',
            otherBag:
              this.extravagantForm.otherBag >= 0
                ? this.extravagantForm.otherBag
                : '',
          }
        }
        data.remark = this.logisticsRemark
        console.log(data)
        print.printPaper(LODOP, data)
      },
      CreatePage() {},
    },
  }
  </script>

  <style scoped lang="scss">
  .takeClothesIndexView {
    font-family: PingFangSC-Regular;
    overflow: auto;

    > .topView {
      padding: 17px 20px;
      overflow: hidden;

      p {
        margin-top: 0;
        cursor: pointer;
      }

      > .tView {
        float: left;
        color: #333;
        text-align: left;
        font-size: 24px;
        font-family: PingFang-SC-Bold;
        line-height: 40px;
      }

      > .btn-add {
        float: right;
      }
    }
  }

  .el-form {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    ::v-deep .el-form-item__label {
      color: #e51d1d;
    }
  }

  .content {
    padding: 0 20px;

    .inputView {
      text-align: center;
    }

    h3 {
      text-align: center;
      font-size: 24px;
    }

    ul {
      display: flex;

      li {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        list-style: none;

        span {
          margin-top: 10px;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }

    .remarkBox {
      text-align: center;
    }

    ::v-deep .el-textarea__inner {
      height: 100px !important;
    }

    .btmFooter {
      margin-top: 20px;
      text-align: center;
    }
  }

  div {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .demo-ruleForm{
    ::v-deep .el-form-item{
      margin-bottom: 10px;
    }
  }
  .sendWashRecordView {
    position: fixed;
    left: 70px;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    background: #fff;
    z-index: 100;
    padding: 60px 20px 25px 25px;
    overflow: auto;

    > .topView {
      background: #fff;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
      padding: 20px 25px 0 25px;
      z-index: 100;

      > .backView {
        height: 30px;

        > .btn-back {
          font-size: 14px;
          color: #666;
          height: 30px;
          padding: 0;
          border: none;
          text-align: left;
        }
      }
    }
  }

  //动画过程
  .userDetails-enter {
    animation: fullscreen-dialog-fade-in 0.9s ease;
  }

  .userDetails-leave {
    animation: fullscreen-dialog-fade-out 0.9s ease forwards;
  }

  .userDetails-enter-active {
    animation: fullscreen-dialog-fade-in 0.9s ease;
  }

  .userDetails-leave-active {
    animation: fullscreen-dialog-fade-out 0.9s ease forwards;
  }

  @keyframes fullscreen-dialog-fade-in {
    0% {
      transform: translate3d(100%, 0, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @keyframes fullscreen-dialog-fade-out {
    0% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    100% {
      transform: translate3d(100%, 0, 0);
      opacity: 0;
    }
  }
  </style>
